.Meet-The-Team-Title {
    background-color: white;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Meet-The-Team-Title h1 {
    margin: 0;
    font-size: 4rem;
}